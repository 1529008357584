var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "envelope-container",
          class: {
            "envelope-closed": _vm.isPaperFilled,
            "envelope-done": _vm.isCardCreated
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.fillPaper.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "envelope", attrs: { id: "envelope" } },
                [
                  _c("div", {
                    staticClass: "envelope-corner",
                    attrs: { id: "envelope-corner" }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "envelope-top" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "envelope-back" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "paper" }, [
                    _c("p", [
                      _c("label", { attrs: { for: "titre" } }, [
                        _vm._v("Occasion")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.occasion,
                            expression: "occasion"
                          }
                        ],
                        attrs: {
                          placeholder:
                            "Anniversaire, Félicitations, Bon rétablissement, Aurevoirs…",
                          id: "occasion",
                          required: ""
                        },
                        domProps: { value: _vm.occasion },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.occasion = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("p", [
                        _c("label", { attrs: { for: "organizer_email" } }, [
                          _vm._v("Votre adresse email")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.organizer_email,
                              expression: "organizer_email"
                            }
                          ],
                          attrs: {
                            type: "email",
                            placeholder: "george@abitbol.com",
                            id: "organizer_email",
                            required: ""
                          },
                          domProps: { value: _vm.organizer_email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.organizer_email = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("label", { attrs: { for: "deadline" } }, [
                        _vm._v("Date limite de participation")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.deadline,
                            expression: "deadline"
                          }
                        ],
                        attrs: { type: "date", id: "deadline" },
                        domProps: { value: _vm.deadline },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.deadline = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "textbox" }, [
                      _c("label", { attrs: { for: "message" } }, [
                        _vm._v("Message aux participants")
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.message,
                            expression: "message"
                          }
                        ],
                        attrs: {
                          placeholder:
                            "Ecrivons un gentil message pour l'anniversaire de Susie !",
                          id: "message",
                          required: ""
                        },
                        domProps: { value: _vm.message },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.message = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm._m(1)
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.createCard.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "envelope-front",
                  attrs: { id: "envelope-front" }
                },
                [
                  _c("div", { staticClass: "receiver" }, [
                    _c("p", [
                      _c("label", { attrs: { for: "receiver_name" } }, [
                        _vm._v("A qui est adressée cette carte:")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.receiver_name,
                            expression: "receiver_name"
                          }
                        ],
                        attrs: {
                          placeholder: "Susie (c'est son anniversaire !)",
                          id: "receiver_name",
                          required: ""
                        },
                        domProps: { value: _vm.receiver_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.receiver_name = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "horizontal" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.notifications_on_new_message,
                            expression: "notifications_on_new_message"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "notifications_on_new_message"
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.notifications_on_new_message
                          )
                            ? _vm._i(_vm.notifications_on_new_message, null) >
                              -1
                            : _vm.notifications_on_new_message
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.notifications_on_new_message,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.notifications_on_new_message = $$a.concat(
                                    [$$v]
                                  ))
                              } else {
                                $$i > -1 &&
                                  (_vm.notifications_on_new_message = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.notifications_on_new_message = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "notifications_on_new_message" } },
                        [_vm._v("Prévenez-moi des message postés")]
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(2)
                  ]),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "stamp",
                    attrs: { src: "/images/stamp.svg", alt: "stamp" }
                  })
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "share-card",
          class: { "show-share-card": _vm.isCardCreated }
        },
        [
          _c("div", { staticClass: "fundraising-link" }, [
            _vm._m(4),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.updateFundraisingLink.apply(null, arguments)
                  }
                }
              },
              [
                _c("div", { staticClass: "fundraising-url" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fundraising_link,
                        expression: "fundraising_link"
                      }
                    ],
                    attrs: {
                      type: "text",
                      id: "fundraising-link",
                      placeholder: "https://lien-vers-ma-cagnotte.fr"
                    },
                    domProps: { value: _vm.fundraising_link },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.fundraising_link = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "bubbly-button",
                    class: { animate: _vm.animate },
                    attrs: { type: "submit", value: "enregistrer" },
                    on: {
                      click: function($event) {
                        return _vm.animateButton()
                      }
                    }
                  })
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("Inviter les participants via")]),
          _vm._v(" "),
          _c("ul", [
            _c(
              "li",
              {
                class: { selected: _vm.isLinkInvitationShown },
                on: {
                  click: function($event) {
                    _vm.isLinkInvitationShown = true
                  }
                }
              },
              [_vm._v("Lien")]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { selected: !_vm.isLinkInvitationShown },
                on: {
                  click: function($event) {
                    _vm.isLinkInvitationShown = false
                  }
                }
              },
              [_vm._v("Email")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "invitations" }, [
            _c(
              "div",
              {
                staticClass: "link-invitation",
                class: { "show-link-invitation": _vm.isLinkInvitationShown }
              },
              [
                _c("p", [
                  _vm._v("Tout le monde peut ajouter un message à cette carte")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "message-url" }, [
                  _vm._m(5),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.addMessageURL,
                        expression: "addMessageURL"
                      }
                    ],
                    attrs: { type: "text", id: "messageURL" },
                    domProps: { value: _vm.addMessageURL },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.addMessageURL = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "copy",
                      attrs: {
                        type: "button",
                        name: "copy",
                        "data-clipboard-target": "#messageURL"
                      }
                    },
                    [_vm._v("copier")]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "email-invitation",
                class: { "show-email-invitation": !_vm.isLinkInvitationShown }
              },
              [
                _c("p", [
                  _vm._v(
                    "Remplir la liste des emails, séparés par des virgules"
                  )
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.updateCard.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("p", [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email_list,
                            expression: "email_list"
                          }
                        ],
                        attrs: { placeholder: "Emails des participants" },
                        domProps: { value: _vm.email_list },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email_list = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "email-submit",
                        class: { shown: !_vm.emailSent }
                      },
                      [
                        _c("input", {
                          attrs: { type: "submit", value: "Envoyer" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "email-submitted",
                        class: { shown: _vm.emailSent }
                      },
                      [_vm._v("Merci :)")]
                    )
                  ]
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bubbles-bg" }),
      _vm._v(" "),
      _c("sharewell-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("h1", { staticClass: "sharewell" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("img", {
            attrs: { src: "/images/logo/logo.svg", alt: "Sharewell" }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("input", { attrs: { type: "submit", value: "Suivant" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { attrs: { id: "create-card" } }, [
      _c("input", { attrs: { type: "submit", value: "Suivant" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "post-stamp-and-lines" }, [
      _c("img", {
        staticClass: "sines",
        attrs: { src: "/images/sines.svg", alt: "post lines" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "post-stamp",
        attrs: { src: "/images/post-stamp.svg", alt: "post stamp" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        staticStyle: { width: "25px", "vertical-align": "bottom" },
        attrs: { src: "/images/moneybag.svg" }
      }),
      _vm._v(
        "\n            Est-ce qu'une cagnotte est associée à cette carte ?\n          "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", { attrs: { src: "/images/chains.svg", alt: "link" } })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-29088344", { render: render, staticRenderFns: staticRenderFns })
  }
}