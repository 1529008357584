<template>
  <div>
      <h1>Merci pour votre message à {{receiver}} !</h1>
  </div>
</template>

<script>
import axios from 'axios';

export default {
	data: function() {
		return {
			receiver: ''
		};
	},
	created: async function() {
		await this.fetchCard();
		this.receiver = this.card.receiver_name;
	},
	methods: {
		fetchCard: async function() {
			let response = await axios.get(`/card/${this.$route.params.code}`);
			this.card = response.data;
		}
	}
};
</script>

<style scoped>
    h2 {
        color: green;
    }
</style>
