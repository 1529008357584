<template>
    <div id="app">

        <footer>
            <span>
              <a href="https://www.instagram.com/sharewell.io/">
                <img src="/images/instagram.svg" alt="" class="social-icon">
              </a>
            </span>
            <span>
                <a href="/faq.html">FAQ</a>
            </span>
            <span>
                <a href="/privacy.html">Privacy policy</a>
            </span>
            <span>
                <a href="/cgv.html">CGV</a>
            </span>
            <span>© Botcave {{(new Date()).getFullYear()}}</span>
        </footer>

    </div>
</template>

<script>
export default {
	data: function() {
		return {};
	}
};
</script>

<style scoped>
footer span {
    margin-left: 20px;
}

a {
    color: #0b6c69;
}

.social-icon {
  width: 50px;
  vertical-align: middle;
}
</style>
