var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("link", {
        attrs: { rel: "stylesheet", href: "/croppie/croppie.css" }
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", { staticClass: "card-message" }, [
        _vm._v(_vm._s(_vm.card_message))
      ]),
      _vm._v(" "),
      _vm.fundraising_link
        ? _c("p", { staticClass: "fundraising-link" }, [
            _c("img", {
              staticStyle: { width: "25px", "vertical-align": "bottom" },
              attrs: { src: "/images/moneybag.svg" }
            }),
            _vm._v(" "),
            _c(
              "a",
              { attrs: { href: _vm.fundraising_link, target: "_blank" } },
              [_vm._v("Contribuer au cadeau de " + _vm._s(_vm.receiver_name))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showModal,
              expression: "showModal"
            }
          ],
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c(
            "h4",
            {
              staticClass: "modal-title",
              attrs: { slot: "header" },
              slot: "header"
            },
            [_vm._v("Prévisualisation")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "image-preview",
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _c("div", { attrs: { id: "image-crop" } }),
              _vm._v(" "),
              _c("div", { staticClass: "image-crop-tools" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.rotate(90)
                      }
                    }
                  },
                  [_vm._v("⟲")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.rotate(-90)
                      }
                    }
                  },
                  [_vm._v("⟳")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "image-crop-tools" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.crop()
                      }
                    }
                  },
                  [_vm._v("Enregistrer")]
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.createMessage.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "paper-container",
              class: {
                "message-sent": _vm.isMessageSent,
                "flying-plane": _vm.isMessageMorphed
              }
            },
            [
              _c("div", { staticClass: "paper" }, [
                _c(
                  "div",
                  {
                    staticClass: "write-message",
                    class: { wizz: _vm.textTooLong }
                  },
                  [
                    _c("p", [
                      _c("label", { attrs: { for: "text" } }, [
                        _vm._v("Votre message à " + _vm._s(_vm.receiver_name))
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.text,
                            expression: "text"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.text_placeholder,
                          required: ""
                        },
                        domProps: { value: _vm.text },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.text = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "character-counter" }, [
                      _c(
                        "div",
                        {
                          staticClass: "remaining-characters",
                          class: { danger: _vm.text.length >= 500 },
                          style: { opacity: _vm.text.length > 480 ? 1 : 0 }
                        },
                        [
                          _vm._v(
                            "\n                          " +
                              _vm._s(500 - _vm.text.length) +
                              "\n                      "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "svg",
                        {
                          staticClass: "radial-counter",
                          attrs: { height: "20", width: "20" }
                        },
                        [
                          _c("circle", {
                            staticClass: "radial-counter-underlay",
                            attrs: {
                              cx: "50%",
                              cy: "50%",
                              r: "8",
                              fill: "none",
                              "stroke-width": "1"
                            }
                          }),
                          _vm._v(" "),
                          _c("circle", {
                            staticClass: "js-progress-circle safe",
                            class: {
                              warn: _vm.text.length > 480,
                              danger: _vm.text.length >= 500
                            },
                            staticStyle: {
                              "stroke-dashoffset": "49.7628",
                              "stroke-dasharray": "50.2655"
                            },
                            style: {
                              "stroke-dashoffset": _vm.radialCounterDashOffset
                            },
                            attrs: {
                              cx: "50%",
                              cy: "50%",
                              r: "8",
                              fill: "none",
                              "stroke-width": "2"
                            }
                          })
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("p", [
                  _c("label", { attrs: { for: "text" } }, [_vm._v("Signé")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.signed_by,
                        expression: "signed_by"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: "Votre (sur)nom",
                      id: "signed_by",
                      required: ""
                    },
                    domProps: { value: _vm.signed_by },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.signed_by = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _vm.can_upload_images
                  ? _c("p", [
                      _c("label", { attrs: { for: "image" } }, [
                        _vm._v("Ajouter une image")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "image-picker" }, [
                        _c(
                          "label",
                          { staticClass: "btn", attrs: { for: "image" } },
                          [_vm._v("Choisir un fichier")]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.croppedImage,
                              expression: "croppedImage"
                            }
                          ],
                          staticClass: "icon-sized",
                          attrs: { src: _vm.croppedImage, alt: "" }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.croppedImage,
                                expression: "croppedImage"
                              }
                            ],
                            staticClass: "btn-remove icon-sized",
                            attrs: { type: "button", name: "button" },
                            on: {
                              click: function($event) {
                                return _vm.deleteCroppedImage()
                              }
                            }
                          },
                          [_vm._v("×")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        ref: "image",
                        attrs: { type: "file", id: "image" },
                        on: {
                          change: function($event) {
                            return _vm.previewFile()
                          }
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", { staticClass: "horizontal" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.visible_by_all,
                        expression: "visible_by_all"
                      }
                    ],
                    attrs: { type: "checkbox", id: "visible_by_all" },
                    domProps: {
                      checked: Array.isArray(_vm.visible_by_all)
                        ? _vm._i(_vm.visible_by_all, null) > -1
                        : _vm.visible_by_all
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.visible_by_all,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.visible_by_all = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.visible_by_all = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.visible_by_all = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "visible_by_all" } }, [
                    _vm._v("Visible par les autres participants")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticStyle: { margin: "0" } },
                  [
                    _vm.isLoading ? _c("loader") : _vm._e(),
                    _vm._v(" "),
                    _c("input", {
                      class: { hide: _vm.isLoading },
                      attrs: { type: "submit", value: "Envoyer" }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("paper-plane-animation", {
                staticClass: "paper-plane-animation"
              }),
              _vm._v(" "),
              _vm.messages.length > 0
                ? _c("h4", { class: { hide: _vm.isGuestbook } }, [
                    _c("a", { attrs: { href: "#other-messages" } }, [
                      _vm._v("Voir ce que les autres personnes ont écrit")
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "h3",
                { staticClass: "thanks", class: { hide: _vm.isGuestbook } },
                [_vm._v("Merci pour votre message !")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("horizontalAd"),
      _vm._v(" "),
      _c("div", { staticClass: "bubbles-bg" }),
      _vm._v(" "),
      _c(
        "h3",
        { class: { hide: _vm.isGuestbook }, attrs: { id: "other-messages" } },
        [_vm._v("Les participants ont déjà écrit...")]
      ),
      _vm._v(" "),
      _vm.messages.length > 0
        ? _c(
            "ul",
            { attrs: { id: "messages-written" } },
            _vm._l(_vm.messages, function(m) {
              return m.visible_by_all
                ? _c(
                    "li",
                    {
                      staticClass: "aniview",
                      attrs: { "data-av-animation": "flipInX" }
                    },
                    [
                      !m.editing
                        ? _c("div", [
                            m.image && _vm.can_upload_images
                              ? _c("img", { attrs: { src: m.image } })
                              : _vm._e(),
                            _vm._v(_vm._s(m.text) + "\n              "),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [_vm._v("– " + _vm._s(m.signed_by))]),
                            _vm._v(" "),
                            m.author_key
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "edit-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.editMessage(m._id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  ✍️ edit this message\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      m.author_key
                        ? _c("div", [
                            m.editing
                              ? _c("div", { staticClass: "editing-box" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "write-message",
                                      class: { wizz: _vm.editTextTooLong }
                                    },
                                    [
                                      _c("p", [
                                        _c(
                                          "label",
                                          { attrs: { for: "text" } },
                                          [_vm._v("Votre message")]
                                        ),
                                        _vm._v(" "),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.editText,
                                              expression: "editText"
                                            }
                                          ],
                                          attrs: {
                                            placeholder: _vm.text_placeholder,
                                            required: ""
                                          },
                                          domProps: { value: _vm.editText },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.editText = $event.target.value
                                            }
                                          }
                                        })
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "character-counter" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "remaining-characters",
                                              class: {
                                                danger:
                                                  _vm.editText.length >= 500
                                              },
                                              style: {
                                                opacity:
                                                  _vm.editText.length > 480
                                                    ? 1
                                                    : 0
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                              " +
                                                  _vm._s(
                                                    500 - _vm.editText.length
                                                  ) +
                                                  "\n                          "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "radial-counter",
                                              attrs: {
                                                height: "20",
                                                width: "20"
                                              }
                                            },
                                            [
                                              _c("circle", {
                                                staticClass:
                                                  "radial-counter-underlay",
                                                attrs: {
                                                  cx: "50%",
                                                  cy: "50%",
                                                  r: "8",
                                                  fill: "none",
                                                  "stroke-width": "1"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("circle", {
                                                staticClass:
                                                  "js-progress-circle safe",
                                                class: {
                                                  warn:
                                                    _vm.editText.length > 480,
                                                  danger:
                                                    _vm.editText.length >= 500
                                                },
                                                staticStyle: {
                                                  "stroke-dashoffset":
                                                    "49.7628",
                                                  "stroke-dasharray": "50.2655"
                                                },
                                                style: {
                                                  "stroke-dashoffset":
                                                    _vm.editRadialCounterDashOffset
                                                },
                                                attrs: {
                                                  cx: "50%",
                                                  cy: "50%",
                                                  r: "8",
                                                  fill: "none",
                                                  "stroke-width": "2"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("label", { attrs: { for: "text" } }, [
                                      _vm._v("Signé")
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.editSignedBy,
                                          expression: "editSignedBy"
                                        }
                                      ],
                                      attrs: {
                                        type: "text",
                                        placeholder: "Votre (sur)nom",
                                        id: "signed_by",
                                        required: ""
                                      },
                                      domProps: { value: _vm.editSignedBy },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.editSignedBy = $event.target.value
                                        }
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "edit-message-buttons" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "cancel-button",
                                          on: {
                                            click: function($event) {
                                              return _vm.closeEditMessage(m._id)
                                            }
                                          }
                                        },
                                        [_vm._v("✖️ annuler")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "edit-button",
                                          on: {
                                            click: function($event) {
                                              return _vm.updateMessage(
                                                m._id,
                                                m.author_key
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("📨 envoyer")]
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { id: "end" } }),
      _vm._v(" "),
      _vm.messages.length === 0
        ? _c(
            "p",
            {
              class: { hide: _vm.isGuestbook },
              staticStyle: { "text-align": "center" }
            },
            [_vm._v("Rien du tout ! A vous de jouer.")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("horizontalAd"),
      _vm._v(" "),
      _c("sharewell-footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("h1", { staticClass: "sharewell" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("img", {
            attrs: { src: "/images/logo/logo.svg", alt: "Sharewell" }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5d0a77fa", { render: render, staticRenderFns: staticRenderFns })
  }
}