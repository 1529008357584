<template>
  <div id="app">
      <link rel="stylesheet" href="/croppie/croppie.css">

      <header>
          <h1 class="sharewell">
            <a href="/"><img src="/images/logo/logo.svg" alt="Sharewell"></a>
          </h1>
      </header>

      <p class="card-message">{{card_message}}</p>

      <p v-if="fundraising_link" class="fundraising-link">
        <img src="/images/moneybag.svg" style="width: 25px; vertical-align: bottom">
        <a :href="fundraising_link" target="_blank">Contribuer au cadeau de {{receiver_name}}</a>
      </p>

    <modal v-show="showModal" @close="showModal = false">
        <h4 slot="header" class="modal-title">Prévisualisation</h4>

        <div slot="body" class="image-preview">
            <div id="image-crop"></div>

            <div class="image-crop-tools">
                <button @click="rotate(90)">⟲</button>
                <button @click="rotate(-90)">⟳</button>
            </div>
            <div class="image-crop-tools">
                <button @click="crop()">Enregistrer</button>
            </div>
        </div>
    </modal>

    <form v-on:submit.prevent="createMessage">
        <div class="paper-container" v-bind:class="{ 'message-sent': isMessageSent, 'flying-plane': isMessageMorphed }">
            <div class="paper">
                <div class="write-message" v-bind:class="{ 'wizz': textTooLong }">
                    <p>
                        <label for="text">Votre message à {{receiver_name}}</label>
                        <textarea v-model="text" v-bind:placeholder="text_placeholder" required></textarea>
                    </p>
                    <div class="character-counter">
                        <div class="remaining-characters" v-bind:style="{ opacity: text.length > 480 ? 1 : 0 }" v-bind:class="{ 'danger': text.length >= 500 }">
                            {{500 - text.length}}
                        </div>
                        <svg class="radial-counter" height="20" width="20">
                            <circle class="radial-counter-underlay" cx="50%" cy="50%" r="8" fill="none" stroke-width="1"></circle>
                            <circle class="js-progress-circle safe" v-bind:class="{ 'warn': text.length > 480, 'danger': text.length >= 500 }" cx="50%" cy="50%" r="8" fill="none" stroke-width="2" v-bind:style="{ 'stroke-dashoffset': radialCounterDashOffset }" style="stroke-dashoffset: 49.7628; stroke-dasharray: 50.2655;"></circle>
                        </svg>
                    </div>
                </div>

                <p>
                    <label for="text">Signé</label>
                    <input v-model="signed_by" type="text" placeholder="Votre (sur)nom" id="signed_by" required>
                </p>

                <p v-if="can_upload_images">
                    <label for="image">Ajouter une image</label>
                    <span class="image-picker">
                        <label for="image" class="btn">Choisir un fichier</label>
                        <img :src="croppedImage" alt="" v-show="croppedImage" class="icon-sized">
                        <button type="button" name="button" v-show="croppedImage" @click="deleteCroppedImage()" class="btn-remove icon-sized">&times;</button>
                    </span>
                    <input type="file" id="image" ref="image" v-on:change="previewFile()" />
                </p>

                <p class="horizontal">
                    <input type="checkbox" id="visible_by_all" v-model="visible_by_all">
                    <label for="visible_by_all">Visible par les autres participants</label>
                </p>

                <p style="margin: 0;">
                    <loader v-if="isLoading"></loader>
                    <input type="submit" value="Envoyer" :class="{hide: isLoading}">
                </p>
            </div>
            <paper-plane-animation class="paper-plane-animation"></paper-plane-animation>
            <h4 v-if="messages.length > 0" :class="{hide: isGuestbook}"><a href="#other-messages">Voir ce que les autres personnes ont écrit</a></h4>

            <h3 class="thanks" :class="{hide: isGuestbook}">Merci pour votre message !</h3>
        </div>
    </form>

    <horizontalAd />

    <div class="bubbles-bg"></div>

    <h3 id="other-messages" :class="{hide: isGuestbook}">Les participants ont déjà écrit...</h3>

    <ul id="messages-written" v-if="messages.length > 0">
        <li v-for="m in messages" v-if="m.visible_by_all" class="aniview" data-av-animation="flipInX">
            <div v-if="!m.editing">
                <img :src="m.image" v-if="m.image && can_upload_images">{{m.text}}
                <br>
                <span>– {{m.signed_by}}</span>
                <button class="edit-button" v-on:click="editMessage(m._id)" v-if="m.author_key">
                    ✍️ edit this message
                </button>
            </div>
            <div v-if="m.author_key">
                <div class="editing-box" v-if="m.editing">
                    <div class="write-message" v-bind:class="{ 'wizz': editTextTooLong }">
                        <p>
                            <label for="text">Votre message</label>
                            <textarea v-model="editText" v-bind:placeholder="text_placeholder" required></textarea> <!-- TODO: utiliser un model unique pour la modification de messages, et fermer tous les autres lorsque l'on édite un message en particulier -->
                        </p>

                        <div class="character-counter">
                            <div class="remaining-characters" v-bind:style="{ opacity: editText.length > 480 ? 1 : 0 }" v-bind:class="{ 'danger': editText.length >= 500 }">
                                {{500 - editText.length}}
                            </div>
                            <svg class="radial-counter" height="20" width="20">
                                <circle class="radial-counter-underlay" cx="50%" cy="50%" r="8" fill="none" stroke-width="1"></circle>
                                <circle class="js-progress-circle safe" v-bind:class="{ 'warn': editText.length > 480, 'danger': editText.length >= 500 }" cx="50%" cy="50%" r="8" fill="none" stroke-width="2" v-bind:style="{ 'stroke-dashoffset': editRadialCounterDashOffset }" style="stroke-dashoffset: 49.7628; stroke-dasharray: 50.2655;"></circle>
                            </svg>
                        </div>
                    </div>

                    <p>
                        <label for="text">Signé</label>
                        <input type="text" placeholder="Votre (sur)nom" id="signed_by" v-model="editSignedBy" required>
                    </p>

                    <div class="edit-message-buttons">
                        <button class="cancel-button" v-on:click="closeEditMessage(m._id)">✖️ annuler</button>
                        <button class="edit-button" v-on:click="updateMessage(m._id, m.author_key)">📨 envoyer</button>
                    </div>
                </div>
            </div>
        </li>
    </ul>

    <div id="end">
    </div>

    <p v-if="messages.length === 0" style="text-align: center;" :class="{hide: isGuestbook}">Rien du tout ! A vous de jouer.</p>

    <horizontalAd />

    <sharewell-footer></sharewell-footer>
  </div>
</template>

<script>
import axios from 'axios';
import PaperPlaneAnimation from './paper_plane_animation.vue';
import loader from './loader.vue';
import horizontalAd from './horizontal_ad.vue'
import VueStroll from 'vue-stroll';
import smoothscroll from 'smoothscroll-polyfill';
import swal from 'sweetalert';
import modal from './modal.vue';
import croppie from 'croppie';
import sharewellFooter from './sharewell_footer.vue';


// kick off the polyfill!
smoothscroll.polyfill();

export default {
	data: function() {
		return {
			code: this.$route.params.code,
			text: '',
			signed_by: '',
            editText: '',
            editSignedBy: '',
            editIsLoading: false,
            editTextTooLong: false,
			visible_by_all: true,
			text_placeholder: 'Ecrivez votre message',
			receiver_name: '',
			card_message: '',
			isMessageSent: false,
			isMessageMorphed: false,
            isMessageSentMobile: false,
            isMessageMorphedMobile: false,
			messages: [],
			radialCounterDashOffset: 50,
			editRadialCounterDashOffset: 50,
			textTooLong: false,
            isGuestbook: false,
            fundraising_link: '',
            isLoading: false,
            imagePreview: null,
            showModal: false,
            croppie: null,
            croppedImage: null
		};
	},
	watch: {
		text: function(newText) {
			let length = newText.length;
			let pct = length * 100 / 500;

			if (pct > 100) pct = 100;

			this.radialCounterDashOffset = 50 - pct / 2;
		},
        editText: function(newText) {
            console.log('editing text…', newText.length)
			let length = newText.length;
			let pct = length * 100 / 500;

			if (pct > 100) pct = 100;

			this.editRadialCounterDashOffset = 50 - pct / 2;
		}
	},
	components: {
		'paper-plane-animation': PaperPlaneAnimation,
        VueStroll,
        loader,
        horizontalAd,
        modal,
        sharewellFooter
	},
	created: async function() {
        setTimeout(this.initCroppie, 0);
		await this.fetchCard();
		this.text_placeholder = `Laissez un message à ${
			this.card.receiver_name
		} pour l'occasion : ${this.card.occasion}`;
		this.receiver_name = this.card.receiver_name;
		this.card_message = this.card.message;
        this.fundraising_link = this.card.fundraising_link;
        this.can_upload_images = this.card.can_upload_images;

        if (this.fundraising_link && !/^https?:\/\//i.test(this.fundraising_link)) {
            this.fundraising_link = 'https://' + this.fundraising_link;
        }

        var options = {
            animateThreshold: 10,
            scrollPollInterval: 10
        };
        $('.aniview').AniView(options);
	},
	methods: {
        initCroppie: function() {
            this.croppie = new Croppie(document.getElementById('image-crop'), {
                viewport: { width: 200, height: 200 },
                boundary: { width: 300, height: 300 },
                enableOrientation: true,
            });
        },
        updateCroppie: function (imageURL) {
            console.log('Updating croppie...');
            this.croppie.bind({ url: imageURL });

            this.croppie.result('blob').then(blob => {
                console.log('BLOB:', blob);
            });
        },
        rotate: function(deg) {
            this.croppie.rotate(deg);
        },
        crop: async function() {
            let croppedImage = await this.croppie.result({
                type: 'base64',
                size: { width: 300 }
            });

            this.croppedImage = croppedImage;
            this.showModal = false;
        },
        deleteCroppedImage: function () {
            this.croppedImage = null;
        },
        previewFile: function (e) {
            let preview = document.querySelector('#preview');
            let files   = document.querySelector('input[type=file]').files;
            let that = this;

            function readAndPreview(file) {
                if ( /\.(jpe?g|png|gif)$/i.test(file.name) ) {
                    let reader = new FileReader();

                    reader.addEventListener("load", function () {
                        that.imagePreview = this.result;
                        that.showModal = true;
                        setTimeout(that.updateCroppie.bind(that, this.result), 500);
                    }, false);

                    reader.readAsDataURL(file);
                }
            }

            if (files) {
                [].forEach.call(files, readAndPreview);
            }
        },
		fetchCard: async function() {
			let code = this.$route.params.code;

            if (code === '5b92e9fbf85cb60bb83d9345')
                this.isGuestbook = true;

			let response = await axios.get(`/card/${code}`);
			this.card = response.data;
			this.messages = this.card.messages.filter(
				m => m.visible_by_all && !m.deleted
			);
		},
		createMessage: async function() {
            this.isLoading = true;
			this.textTooLong = false;

			if (this.text.length > 500) {
				this.textTooLong = true;
				setTimeout(() => {
					this.textTooLong = false;
				}, 600);
				return;
			}

			const fields = ['text', 'signed_by', 'visible_by_all'];

            let formData = new FormData();

            let files = this.$refs.image && this.$refs.image.files;

            if (this.croppedImage)
                formData.append('image', this.croppedImage);

            fields.forEach(field => (formData.append(field, this[field])));

			let code = this.$route.params.code;

            let response;

            try {
                response = await axios.post(`/card/${code}/message`, formData);
            } catch (error) {
                this.isLoading = false;
                switch (error.response.data) {
                    case 'File too large':
                    return swal("Ooops !", "Le fichier que vous avez tenté d'uploader est trop volumineux.", "error");
                    break;

                    default:
                    return swal("Ooops !", "Le fichier joint n'a pas pu être uploadé.", "error");
                    break;
                }
            }

            this.isLoading = false;

            if (this.isGuestbook) {
                setTimeout(() => {
                document.location.reload();
                }, 8000);
            }

            // Phone animation
            if (window.innerWidth <= 767) {
                this.messages.push({
                text: this.text,
                signed_by: this.signed_by,
                visible_by_all: true
                });

                var options = {
                    animateThreshold: 10,
                    scrollPollInterval: 10
                };
                $('.aniview').AniView(options);

                document.querySelector('#end').scrollIntoView({
                behavior: 'smooth'
                });

                this.isMessageSentMobile = true;
                setTimeout(() => {
                        this.isMessageMorphedMobile = true;
                    }, 1000);
                return;
            }

			this.isMessageSent = true;

			setTimeout(() => {
				this.isMessageMorphed = true;
			}, 1000);
		},
        editMessage: function(id) {
            this.messages = this.messages.map(x => {
                x.editing = false;
                if (x._id == id) {
                    x.editing = true;
                    this.editText = x.text;
                    this.editSignedBy = x.signed_by;

                }
                return x;
            });
        },
        closeEditMessage: function(id) {
            this.messages = this.messages.map(x => {
                if (x._id == id) x.editing = false;
                return x;
            });
        },
        getCookie: function(n) {
            let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
            return a ? a[1] : '';
        },
        updateMessage: async function(messageId, authorKey) {
            this.editIsLoading = true;
			this.editTextTooLong = false;

			if (this.editText.length > 500) {
				this.editTextTooLong = true;
				setTimeout(() => {
					this.editTextTooLong = false;
				}, 600);
				return;
			}

            try {
                await axios.put(`/message/${messageId}`, {
                    text: this.editText,
                    signed_by: this.editSignedBy,
                    author_key: authorKey,
                });
            } catch (error) {
                this.editIsLoading = false;
                switch (error.response.data) {
                    default:
                    return swal("Ooops !", "Le message n'a pas pu être édité", "error");
                    break;
                }
            }

            await this.fetchCard();

            this.editIsLoading = false;
        }
	}
};
</script>

<style>
.cr-viewport, .cr-vp-square {
    border-top-right-radius: 15px!important;
    border-top-left-radius: 15px!important;
}

.cr-viewport.cr-vp-square:after {
    content: '– Your name';
    display: block;
    width: 100%;
    height: 35px;
    background: white;
    position: absolute;
    bottom: -41px;
    border: 2px solid white;
    left: -2px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: center;
    line-height: 30px;
    opacity: 0.7;
}
</style>

<style scoped>
header {
    text-align: center;
}

.sharewell {
    font-family: Lato;
    color: #f7555f;
    text-shadow: 1px 1px 0px #b4232c, 2px 2px 0px #b4232c, 3px 3px 0px #b4232c, 4px 4px 0px #b4232c, 5px 5px 0px #b4232c, 6px 6px 0px #b4232c;
}

.sharewell img {
  width: 350px;
  max-width: 80%;
}

h4 {
    text-align: center;
    margin: 0;
}

body {
    background-color: #a5f4f1; /*rgba(243, 139, 86, 0.64);*/
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    position: relative;
    overflow: hidden;
}

ul {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
}

li {
    flex-grow: 1;
    width: 280px;
    max-width: 280px;
    display: block;
    background: white;
    margin: 10px;
    padding: 20px;
    border-radius: 15px;
}

li span {
    text-align: right;
    display: block;
    margin-top: 15px;
}

.card-message {
    color: #18486f;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    line-height: 27px;
    position: relative;
}

.card-message:before {
    content: '\201C';
    font-size: 40px;
    font-family: serif;
    position: absolute;
    left: -20px;
}

.card-message:after {
    content: '\201D';
    font-size: 40px;
    font-family: serif;
    position: absolute;
    bottom: -10px;
    right: -20px;
}

.paper{
display: flex;
justify-content: space-between;
flex-direction: column;
background-color: #fff;
width: 93%;
height: 410px;
z-index: 10;
margin-bottom: 20px;
box-shadow: 0 0 3px rgba(0,0,0, 0.2);
padding: 12px 20px;
box-sizing: border-box;
position: relative;
margin: 60px auto;
max-width: 600px;
border: 0px solid white;
}

.receiver {
    width: 93%;
    margin: 0 auto;
}

.paper p,
.receiver p {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    margin: 0;
}

.paper p label,
.receiver p label {
    font-size: 14px;
    color: #1e605e;
    font-weight: bold;
}

.receiver p label {
    color: #1e605e;
    font-weight: bold;
}

.receiver ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #a7816d;
}
.receiver :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #a7816d;
    opacity: 1;
}
.receiver ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #a7816d;
    opacity: 1;
}
.receiver :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #a7816d;
}
.receiver ::-ms-input-placeholder { /* Microsoft Edge */
    color: #a7816d;
}
.receiver ::placeholder { /* Most modern browsers support this now. */
    color: #a7816d;
}

.receiver input {
    color: #593929;
    border-bottom: 1px dashed #c08667;
}

input, textarea, .paper p label.btn {
    border: 0;
    border-bottom: 1px dashed #d9d9d9;
    font-size: 16px;
    background: transparent;
    padding: 5px 3px;
    resize: none;
    transition: 0.4s all ease-in-out;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.double-fields {
    display: flex;
    min-height: 50px;
    margin: 0px 20px 6px 0;
}

.paper p.horizontal,
.receiver p.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

input[type=file] {
    display: none;
}

input[type=submit], .paper p label.btn {
    background: #ea525c;
    color: white;
    border-style: solid;
    cursor: pointer;
    width: 140px;
    margin: 0 auto;
    text-align: center;
}

input[type="submit"]:hover, .paper p label.btn:hover {
    background: #c32831;
}

.paper p label.btn {
    background: #17b5af;
    border-radius: 27px;
}

.paper p label.btn:hover {
    background: #07706c;
}

.btn-remove {
    margin: 0;
    padding: 0 9px;
    font-size: 32px;
    line-height: 1px;
    background: #afafaf;
    padding-bottom: 5px;
    border: none;
}

.image-picker {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-picker > * {
    margin-left: 5px!important;
    margin-right: 5px!important;
}

.icon-sized {
    height: 31px;
    border-radius: 5px;
}

textarea {
    min-height: 70px;
    padding-right: 60px;
}

.textbox {
    height: 90px;
    flex-grow: 1;
    flex-shrink: 0;
}

.bubbles-bg {
    background-image: url(/images/bubbles.svg);
    height: 200px;
    width: 100%;
    margin: 100px 0 30px 0;
    background-repeat-y: no-repeat;
}

.paper-container {
    position: relative;
    width: 100vw;
    overflow-x: hidden;
    overflow: hidden;
    max-width: 100%;
}

.paper, .paper * {
    transition: 1s all ease-in-out;
    transition: 1.2s all ease-in-out;
}

.paper-plane-animation {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -40px;
    transition: 0.4s all ease-in-out;
    transition-delay: 0.8s;
}

.message-sent .paper-plane-animation {
    opacity: 1;
    z-index: 1000;
    transform: scaleX(1);
}

.message-sent .paper {
    transform: scaleX(0.5);
    opacity: 0;
    /* width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 92px solid transparent;
    border-left: 160px solid white;
    display: block;
    background: 0;
    box-shadow: none;
    padding: 0;
    transform: rotate(-30deg) skew(28deg) translate(51px, -2px);

    -webkit-animation-delay: 20s;

    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;

    -webkit-animation: disappearing-triangle 1.5s forwards;
    -moz-animation: disappearing-triangle 1.5s forwards;
    animation: disappearing-triangle 1.5s forwards; */
}

.message-sent .paper * {
    /* opacity: 0;
    transform: scale(0); */
}

.paperplane {
    opacity: 0;
    transition: 0.4s all ease-in-out;
    transition-delay: 0.2s;
    width: 190px;
    margin: 0 auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: none;
}

.message-sent .paperplane {
    /* opacity: 1;

    animation-delay: 0s;

    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;

    -webkit-animation: paper-plane-soaring 4s forwards;
    -moz-animation: paper-plane-soaring 4s forwards;
    animation: paper-plane-soaring 4s forwards; */
}

@-webkit-keyframes disappearing-triangle {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes paper-plane-soaring {
    0% {
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
        transform: rotate(15deg);
        left: -100px;
        top: 0px;
    }
    30% {
        -webkit-transform: rotate(30deg);
        -moz-transform: rotate(30deg);
        transform: rotate(30deg);
    }
    40% {
        -webkit-transform: rotate(30deg);
        -moz-transform: rotate(30deg);
        transform: rotate(30deg);
    }
    60% {
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    70% {
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        transform: rotate(5deg);
        top: 50px;
    }
    100% {
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
        transform: rotate(15deg);
        left: 100%;
        top: 0px;
    }
}

.thanks {
    opacity: 0;
    transition: 2s all ease-in-out;
    text-align: center;
}

.flying-plane .thanks {
    transition-delay: 4s;
    opacity: 1;
    transform: translateY(-250px);
    text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
}

.message-sent .paper p label,
.message-sent .receiver p label {
    width: 222px;
    background: #b58c77;
    margin-bottom: 10px;
    height: 10px;
    color: transparent;
}

.message-sent input[type=text] {
    background: #3e3d3dfa;
    color: transparent;
    height: 0px;
    width: 70%;
    border-bottom: 0;
}

.message-sent input[type=submit] {
    background: #ea525c;
    color: transparent;
    border-style: solid;
    cursor: pointer;
    width: 140px;
    margin: 0 auto;
}

.message-sent input[type=checkbox] {
    opacity: 0;
}

.message-sent textarea {
    background: #3e3d3dfa;
    height: 0px;
    min-height: 0px;
    border: none;
    position: relative;
    margin-bottom: 10px;
    position: relative;
    background-image: linear-gradient(0deg, #ffffff 25%, #3e3d3d 25%, #3e3d3d 50%, #ffffff 50%, #ffffff 75%, #3e3d3d 75%, #3e3d3d 100%);
    background-size: 458px;
    height: 41px;
    color: transparent;
    border: 0!important;
}

.message-sent textarea:before,
.message-sent textarea:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    top: 30px;
    left: 0;
    right: 0;
    background: #3e3d3dfa;
    height: 0px;
    min-height: 0px;
    border: none;
    position: relative;
    margin-bottom: 10px;
}

.hide {
  display: none;
}


/* Characters counter */

svg.radial-counter {
  overflow: visible;
  transform: rotate(-90deg);
}

.radial-counter .safe {
  stroke: #1da1f2;
}

.radial-counter .warn {
  stroke: #ffad1f;
}

.radial-counter .danger {
  stroke: #e0245e;
}

.radial-counter-underlay {
  stroke: #ccd6dd;
}

@keyframes RadialCounterPulse {
  0% { stroke-width:2 }
  50% { stroke-width: 4; }
  100% { stroke-width: 2; }
}

.radial-counter .warn,
.radial-counter .danger {
  animation: RadialCounterPulse 0.3s ease-in-out;
  animation-iteration-count: 1;
}

.write-message {
    position: relative;
}

.character-counter {
    position: absolute;
    bottom: 4px;
    right: 0;
    display: flex;
}

.remaining-characters {
    padding: 0 5px;
    color: #a1a1a1;
}

.remaining-characters.danger {
    color: #e0245e;
}

.wizz {
    animation: wizz 0.5s ease-in-out;
    animation-iteration-count: 1;
}

@keyframes wizz {
    0% { transform: scale(1) rotate(0deg); }
    25% { transform: scale(1.1) rotate(5deg); }
    50% { transform: scale(1.1) rotate(-5deg); }
    75% { transform: scale(1.1) rotate(5deg); }
    100% { transform: scale(1) rotate(0deg); }
}

.fundraising-link {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0;
}

a {
    color: #0b6c69;
}

#messages-written img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
}

.loader {
    margin: 0 auto;
}

.image-preview img {
    max-width: 80%;
    margin: 0 auto;
    display: block;
    box-shadow: 0 8px 21px #00000059;
}

h4 {
    font-size: 22px;
    line-height: 52px;
    color: #5d7777;
}

button {
    margin: 0 auto;
    padding: 4px 20px;
    font-size: 19px;
    border-radius: 10px;
    margin-bottom: 10px;
    width: auto;
    -webkit-appearance: none;
    background: #ea525c;
    color: white;
    border-style: solid;
    cursor: pointer;
}

.image-crop-tools {
    text-align: center;
}

/* Phone media queries */
@media (max-width: 767px) {
    .sharewell {
      margin: 0;
    }

    ul {
      padding-bottom: 200px;
    }

    .card-message {
        max-width: 100%;
        max-width: none;
        padding: 0 20px;
    }

    .card-message:before,
    .card-message:after {
        content: '';
    }

    .paper {
        margin: 40px 0 0 0;
        width: 100%;
        height: auto;
        max-width: none;
        box-shadow: 0 0 43px rgba(24, 72, 110, 0.28);
        background: #efefef;
        padding: 30px 20px;
    }

    .paper label {
      margin-bottom: 20px;
      color: #1e605e;
      font-weight: bold;
    }

    .paper textarea,
    .paper input[type=text] {
      margin-bottom: 30px;
      background: white;
      padding: 19px;
      font-size: 18px;
      border: none;
      border-radius: 15px;
    }

    input[type=submit] {
        margin: 0 auto;
        padding: 20px 50px;
        font-size: 22px;
        border-radius: 10px;
        margin-bottom: 10px;
        width: auto;
        -webkit-appearance: none;
    }

    h4 {
        margin-top: 60px;
        z-index: 1000;
        position: relative;
    }

    h4 a {
        color: #195b8a;
    }

    h4.modal-title {
        margin-top: 0;
    }

    .flying-plane .thanks {
        transform: translateY(-380px);
        padding: 0 30px;
    }

    .bubbles-bg {
        margin: 0;
    }

    #end {
    }
}

.edit-button {
    background: aliceblue;
    display: inline-block;
    border: 1px solid #78b4e9;
    padding: 3px 9px;
    border-radius: 20px;
    color: #0b5697;
    font-size: 14px;
    display: flex;
    margin-top: 25px;
    margin-bottom: 0;
}

.edit-button:hover {
    background: #e2f1ff;
}

.edit-button:active {
    background: #cae0f5;
}

.cancel-button {
    background: hsl(208 18% 97% / 1);
    display: inline-block;
    border: 1px solid hsl(208 18% 69% / 1);
    padding: 3px 9px;
    border-radius: 20px;
    color: hsl(208 18% 32% / 1);
    font-size: 14px;
    display: flex;
    margin-top: 25px;
    margin-bottom: 0;
}

.cancel-button:hover {
    background: hsl(209 18% 94% / 1);
}

.cancel-button:active {
    background: hsl(209 18% 88% / 1);
}

.edit-message-buttons {
    display: flex;
    justify-content: center;
}

.edit-message-buttons .edit-button,
.edit-message-buttons .cancel-button {
    margin: 0 5px;
}

.editing-box label {
    font-size: 14px;
    color: #1e605e;
    font-weight: bold;
}

.editing-box .write-message p {
    margin-top: 0;
}

</style>
