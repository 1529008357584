<template>
  <div>
      <h1>Merci, votre carte a bien été créée !</h1>
      <h2>Vous devriez recevoir sous peu un email de confirmation.</h2>

      <p>En attendant, vous pouvez <a v-bind:href="url">écrire un message ici !</a></p>
  </div>
</template>

<script>
export default {
	data: function() {
		return {
			url: `/message/${this.$route.params.code}`
		};
	}
};
</script>

<style scoped>
    h2 {
        color: green;
    }
</style>
