var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("footer", [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("span", [_vm._v("© Botcave " + _vm._s(new Date().getFullYear()))])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("a", { attrs: { href: "https://www.instagram.com/sharewell.io/" } }, [
        _c("img", {
          staticClass: "social-icon",
          attrs: { src: "/images/instagram.svg", alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("a", { attrs: { href: "/faq.html" } }, [_vm._v("FAQ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("a", { attrs: { href: "/privacy.html" } }, [_vm._v("Privacy policy")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("a", { attrs: { href: "/cgv.html" } }, [_vm._v("CGV")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e62a8a28", { render: render, staticRenderFns: staticRenderFns })
  }
}